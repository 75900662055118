import { useState } from 'react';
import getFilesDetails from '@/files/utils/getFilesDetails';
import getImageOptimizeDetails from '@/storychief/utils/getImageOptimizeDetails';
import useModal from '@/modals/hooks/useModal';

function defaultAllowedFileTypesErrorMessage(allowedFileTypes) {
  return `Only ${allowedFileTypes.map((fileType) => fileType.extension).join(', ')} are allowed`;
}

function _validateWidthAndHeight(file, minWidth, minHeight) {
  let isValid = true;
  let isExact = true;

  if (minWidth && file.width < minWidth) {
    isValid = false;
  }
  if (minHeight && file.height < minHeight) {
    isValid = false;
  }
  if (minWidth && file.width !== minWidth) {
    isExact = false;
  }
  if (minHeight && file.height !== minHeight) {
    isExact = false;
  }
  return { isValid, isExact };
}

function _getFileType(file) {
  return file.id ? file.mime_type : file.type;
}

function _validateAllowedFileTypes(files, allowedFileTypes) {
  let allowed = true;
  files.forEach((_file) => {
    const type = _getFileType(_file);
    if (!allowedFileTypes.find((fileType) => type.indexOf(fileType.mime) !== -1)) {
      allowed = false;
    }
  });
  return allowed;
}

function _validateVideoFilesSize(files) {
  let isValidSize = true;
  files.forEach((_file) => {
    const type = _getFileType(_file);
    if (type.startsWith('video') && _file.size >= 1024 * 1024 * 500 /* MB */) {
      isValidSize = false;
    }
  });
  return isValidSize;
}

export default function useLoadFiles({
  allowedFileTypes = null,
  minWidth = null,
  minHeight = null,
  crop = false,
  convertPngToJpeg = false,
  onCompleted = null,
  resetOnComplete = false,
  allowedFileTypesErrorMessage,
}) {
  // Hooks
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const imageCropModal = useModal('ImageCrop');
  const imageConvertModal = useModal('ImageConvert');

  // Functions
  function showCropModal(files) {
    imageCropModal.toggle({
      props: {
        width: parseInt(minWidth, 10),
        height: parseInt(minHeight, 10),
        onSaveCallback: (_image) => load([_image]),
        image: files[0],
      },
    });
  }

  function showConvertModal(_optimizedImages, _originalImages) {
    imageConvertModal.toggle({
      props: {
        onCancel: () => {
          handleOnLoaded(_originalImages);
        },
        onConfirm: () => {
          handleOnLoaded(_optimizedImages);
        },
        optimizedImages: _optimizedImages,
      },
    });
  }

  function handleOnLoaded(files) {
    onCompleted(files);

    if (resetOnComplete) {
      setLoading(false);
    }
  }

  function getAllowedFileTypesErrorMessage(_files) {
    if (
      typeof allowedFileTypesErrorMessage === 'function' &&
      allowedFileTypesErrorMessage(_files)
    ) {
      return allowedFileTypesErrorMessage(_files);
    }

    return defaultAllowedFileTypesErrorMessage(allowedFileTypes);
  }

  function load(files) {
    setCalled(true);
    const _files = Array.from(files);
    if (!_validateAllowedFileTypes(_files, allowedFileTypes)) {
      setLoading(false);
      // eslint-disable-next-line no-alert
      window.alert(getAllowedFileTypesErrorMessage(_files));
      return;
    }

    if (!_validateVideoFilesSize(_files)) {
      // eslint-disable-next-line no-alert
      window.alert('Video must be smaller than 500 MB');
      return;
    }

    getFilesDetails(_files).then((_filesWithDetails) => {
      if (minWidth || minHeight) {
        // minWidth and minHeight functionality only works for first file
        const { isValid, isExact } = _validateWidthAndHeight(
          _filesWithDetails[0],
          minWidth,
          minHeight,
        );
        if (isValid) {
          if (crop && !isExact) {
            showCropModal(_filesWithDetails);
          } else if (convertPngToJpeg && !_filesWithDetails[0].id) {
            getImageOptimizeDetails([_filesWithDetails[0]]).then(
              ({ images: _optimizedImages, savings }) => {
                if (savings) {
                  showConvertModal(_optimizedImages, [_filesWithDetails[0]]);
                } else {
                  handleOnLoaded([_filesWithDetails[0]]);
                }
              },
            );
          } else {
            handleOnLoaded([_filesWithDetails[0]]);
          }
        } else {
          setLoading(false);
          // eslint-disable-next-line no-alert
          window.alert('Your image is too small, please upload a larger image');
        }
      } else if (convertPngToJpeg && !_filesWithDetails[0].id) {
        getImageOptimizeDetails(_filesWithDetails).then(({ images: _optimizedImages, savings }) => {
          if (savings) {
            showConvertModal(_optimizedImages, _filesWithDetails);
          } else {
            handleOnLoaded(_filesWithDetails);
          }
        });
      } else {
        handleOnLoaded(_filesWithDetails);
      }
    });
  }

  return [
    load,
    {
      loading,
      called,
    },
  ];
}
