import PropTypes from 'prop-types';
import { MenuItem } from 'react-bootstrap';
import {
  DAM_INTEGRATIONS_WITHOUT_SETUP,
  INTEGRATION_NAMES,
  INTEGRATION_DAM_UNSPLASH,
} from '@/storychief/constants/Constants';
import { useCloudStorage } from '@/storychief/hooks';
import convertTextToSlug from '@/storychief/utils/convertTextToSlug';
import StoryChief from '@/storychief';

const propTypes = {
  damIntegrationsWithSetup: PropTypes.arrayOf(PropTypes.shape({})),
  onFileLoaded: PropTypes.func.isRequired,
  allowedFileTypes: PropTypes.arrayOf(
    PropTypes.shape({
      mime: PropTypes.string.isRequired,
      extension: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onFileLoading: PropTypes.func,
  onFileLoadError: PropTypes.func,
  multiSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  hideUnsplash: PropTypes.bool,
};

const defaultProps = {
  damIntegrationsWithSetup: [],
  onFileLoading: null,
  onFileLoadError: null,
  multiSelect: false,
  disabled: false,
  loading: false,
  hideUnsplash: false,
};

function DAMMenuItems({
  damIntegrationsWithSetup,
  onFileLoaded,
  onFileLoading,
  onFileLoadError,
  multiSelect,
  disabled,
  loading,
  allowedFileTypes,
  hideUnsplash,
}) {
  // Hooks
  const openCloudStorage = useCloudStorage({
    allowedFileTypes,
    multiSelect,
    onFileLoaded,
    onFileLoading,
    onFileLoadError,
  });

  // Functions
  function onMenuItemClick(integration) {
    openCloudStorage(integration.integration_type, integration.info);
  }

  if (loading) {
    return (
      <div className="text-center">
        <span className="animated-spin d-inline-block text-info">
          <span className="icon-spin1" />
        </span>
      </div>
    );
  }

  const damIntegrations = DAM_INTEGRATIONS_WITHOUT_SETUP.filter(
    (_integrationType) => !(hideUnsplash && _integrationType === INTEGRATION_DAM_UNSPLASH),
  ).map((_integrationType) => ({
    id: `predefined-${_integrationType}`,
    display_name: INTEGRATION_NAMES[_integrationType],
    integration_type: _integrationType,
    slug: convertTextToSlug(INTEGRATION_NAMES[_integrationType]),
  }));

  damIntegrations.unshift(
    ...damIntegrationsWithSetup.map((_integration) => ({
      ..._integration,
      slug: convertTextToSlug(INTEGRATION_NAMES[_integration.integration_type]),
    })),
  );

  return (
    <>
      {damIntegrations.map((_integration) => (
        <MenuItem
          key={_integration.id}
          onSelect={() => onMenuItemClick(_integration)}
          disabled={disabled}
        >
          <div className="d-flex flex-align-items-center flex-row">
            <em className={`sprite-channel sprite-integration-xs ${_integration.slug}`} />
            <div className="gutter-left-2">Upload from {_integration.display_name}</div>
          </div>
        </MenuItem>
      ))}
      <MenuItem divider />
      <MenuItem
        disabled={disabled}
        href={`${StoryChief.dashboardBasePath}/integrations#cloud-storage`}
      >
        <div className="d-flex flex-align-items-center flex-row">
          <em className="icon-tools" />
          <div className="gutter-left-1">Connect other services</div>
        </div>
      </MenuItem>
    </>
  );
}

DAMMenuItems.propTypes = propTypes;
DAMMenuItems.defaultProps = defaultProps;

export default DAMMenuItems;
