/* eslint-disable no-param-reassign */
import convertDataUriToBlob from '@/storychief/utils/convertDataUriToBlob';
import getImageIsPNG from '@/storychief/utils/getImageIsPNG';

function createCanvas() {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.classList.add('sr-only');
  document.body.appendChild(canvas);

  return [canvas, context];
}

function setCanvas({ canvas, context, image }) {
  canvas.width = image.naturalWidth;
  canvas.height = image.naturalHeight;
  context.clearRect(0, 0, canvas.width, canvas.height);
  // Set a white background to show if the image is transparent (a black background is set by
  // default).
  context.fillStyle = '#ffffff';
  context.fillRect(0, 0, canvas.width, canvas.height);
}

function getOptimizedImageBlob(name, dataUri) {
  const blob = convertDataUriToBlob(dataUri);
  blob.lastModifiedDate = new Date();
  blob.name = name.replace(/.png$/, '.jpg');

  return blob;
}

export default function getImageOptimizeDetails(images) {
  const [canvas, context] = createCanvas();
  return new Promise((resolveDetails) => {
    Promise.all(
      images.map(
        (_image) =>
          new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener('load', (_event) => {
              _image.isPNG = getImageIsPNG(new Uint8Array(_event.target.result));
              resolve(_image);
            });
            reader.readAsArrayBuffer(_image);
          }),
      ),
    ).then((_images) => {
      Promise.all(
        _images.map(
          (_image) =>
            new Promise((resolve) => {
              if (_image.isPNG) {
                const reader = new FileReader();
                reader.addEventListener('load', (_event) => {
                  const imageElement = new Image();
                  imageElement.src = _event.target.result;
                  imageElement.onload = () => {
                    setCanvas({
                      canvas,
                      context,
                      image: imageElement,
                    });
                    context.drawImage(imageElement, 0, 0);
                    const blob = getOptimizedImageBlob(_image.name, canvas.toDataURL('image/jpeg'));
                    const percentageSavings = Math.floor(100 - (blob.size / _image.size) * 100);

                    if (percentageSavings > 10) {
                      // We need to add missing properties from the original image. For example source and external_id
                      Object.entries(_image).forEach(([_key, _value]) => {
                        if (!hasOwnProperty.call(blob, _key)) {
                          blob[_key] = _value;
                        }
                      });
                      blob.isPNG = false;
                      blob.savings = _image.size - blob.size;
                      resolve(blob);
                    } else {
                      _image.savings = 0;
                      resolve(_image);
                    }
                  };
                });
                reader.readAsDataURL(_image);
              } else {
                _image.savings = 0;
                resolve(_image);
              }
            }),
        ),
      ).then((_imagesOptimized) => {
        canvas.remove();
        resolveDetails({
          images: _imagesOptimized,
          savings: _imagesOptimized.reduce((total, _image) => total + _image.savings, 0),
        });
      });
    });
  });
}
