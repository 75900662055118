import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const propTypes = {
  children: PropTypes.node.isRequired,
  attachmentType: PropTypes.string.isRequired,
};

function PostsetAttachmentTypeOverlayTrigger({ children, attachmentType }) {
  return (
    <OverlayTrigger
      placement="top"
      key={`postset-type-overlay-trigger-${attachmentType}`}
      trigger={['hover']}
      delayShow={100}
      overlay={
        <Tooltip id={`postset-type-overlay-trigger-${attachmentType}-tooltip`}>
          {attachmentType}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
}

PostsetAttachmentTypeOverlayTrigger.propTypes = propTypes;

export default PostsetAttachmentTypeOverlayTrigger;
