import dynamicScriptLoad from './dynamicScriptLoad';

/**
 * @param {{id: string, src: string, dataAppKey: string}[]} scripts
 * @param {string} dataAppKey
 * @returns {Promise}
 */
export default async function dynamicScriptsLoad(scripts, dataAppKey) {
  return Promise.all(scripts.map(({ id, src }) => dynamicScriptLoad({ id, src, dataAppKey })));
}
