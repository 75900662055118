import { INTEGRATION_DAM_DROPBOX } from '@/storychief/constants/Constants';

export default function loadDropboxMedia({
  allowedFileTypes,
  onFileLoaded,
  onFileLoadError,
  onFileLoading,
  multiSelect,
}) {
  const options = {
    success(files) {
      onFileLoading();
      const filesArray = [...files];
      try {
        Promise.all(
          filesArray.map(
            (_file) =>
              new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                const url = _file.link;
                xhr.open('GET', url);
                xhr.responseType = 'blob';
                xhr.addEventListener('error', reject);
                xhr.addEventListener('load', (event) => {
                  const blob = event.currentTarget.response;
                  resolve({ blob, name: _file.name });
                });

                xhr.send();
              }),
          ),
        )
          .then((objects) => {
            const _files = objects.map((_object) => {
              const _file = new File([_object.blob], _object.name, {
                type: _object.blob.type,
                lastModifiedDate: new Date(),
              });
              _file.source = INTEGRATION_DAM_DROPBOX;
              return _file;
            });
            onFileLoaded(_files);
          })
          .catch((e) => {
            onFileLoadError(e);
          });
      } catch (e) {
        onFileLoadError(e);
      }
    },
    linkType: 'direct',
    multiselect: multiSelect,
    extensions: allowedFileTypes.map((_filetype) => _filetype.extension),
  };

  window.Dropbox.choose(options);
}
