import {
  INTEGRATION_DAM_CANTO,
  INTEGRATION_DAM_GOOGLE_DRIVE,
  INTEGRATION_DAM_DROPBOX,
  INTEGRATION_DAM_CLOUDINARY,
  INTEGRATION_DAM_BYNDER,
  INTEGRATION_DAM_UNSPLASH,
  INTEGRATION_DAM_ONEDRIVE,
} from '@/storychief/constants/Constants';
import dynamicScriptLoad from '@/storychief/utils/dynamicScriptLoad';
import dynamicScriptsLoad from '@/storychief/utils/dynamicScriptsLoad';
import loadGoogleDriveMedia from '@/storychief/utils/loadGoogleDriveMedia';
import loadDropboxMedia from '@/storychief/utils/loadDropboxMedia';
import loadCloudinaryMedia from '@/storychief/utils/loadCloudinaryMedia';
import loadBynderMedia from '@/storychief/utils/loadBynderMedia';
import loadOneDriveMedia from '@/storychief/utils/loadOneDriveMedia';
import useModal from '@/modals/hooks/useModal';
import { sources } from '@/media/constants/Constants';

export default function useCloudStorage({
  allowedFileTypes,
  multiSelect,
  onFileLoaded,
  onFileLoading,
  onFileLoadError,
}) {
  const _onFileLoading = onFileLoading || (() => {});
  const _onFileLoadError = onFileLoadError || (() => {});
  const cantoModal = useModal('CantoModal');
  const mediaLibraryModal = useModal('MediaLibraryModal');

  async function openService(service, integrationInfo) {
    switch (service) {
      case INTEGRATION_DAM_UNSPLASH:
        mediaLibraryModal.toggle({
          props: {
            multiSelect,
            allowedFileTypes,
            onInsert: onFileLoaded,
            defaultSource: sources.UNSPLASH.id,
          },
        });
        break;
      case INTEGRATION_DAM_CANTO:
        cantoModal.toggle({
          props: {
            onFileLoaded,
            onFileLoadError: _onFileLoadError,
            onFileLoading: _onFileLoading,
            environment: integrationInfo?.environment,
          },
        });
        break;
      case INTEGRATION_DAM_GOOGLE_DRIVE:
        await dynamicScriptsLoad([
          {
            id: 'googleDriveJS',
            src: 'https://apis.google.com/js/api.js',
          },
          { id: 'googleDriveGisJS', src: 'https://accounts.google.com/gsi/client' },
        ]);
        loadGoogleDriveMedia({
          allowedFileTypes,
          onFileLoaded,
          onFileLoadError: _onFileLoadError,
          onFileLoading: _onFileLoading,
          multiSelect,
        });
        break;
      case INTEGRATION_DAM_ONEDRIVE:
        await dynamicScriptLoad({
          id: 'oneDriveJS',
          src: 'https://js.live.net/v7.2/OneDrive.js',
        });
        loadOneDriveMedia({
          allowedFileTypes,
          onFileLoaded,
          onFileLoadError: _onFileLoadError,
          onFileLoading: _onFileLoading,
          multiSelect,
        });
        break;
      case INTEGRATION_DAM_DROPBOX:
        await dynamicScriptLoad({
          id: 'dropboxjs', // id must be dropboxjs in order for SDK to work
          src: 'https://www.dropbox.com/static/api/2/dropins.js',
          dataAppKey: window.StoryChief.services.dropbox.appKey,
        });
        loadDropboxMedia({
          allowedFileTypes,
          onFileLoaded,
          onFileLoadError: _onFileLoadError,
          onFileLoading: _onFileLoading,
          multiSelect,
        });
        break;
      case INTEGRATION_DAM_CLOUDINARY:
        await dynamicScriptLoad({
          id: 'cloudinaryJS',
          src: 'https://media-library.cloudinary.com/global/all.js',
        });
        loadCloudinaryMedia({
          integrationInfo,
          allowedFileTypes,
          onFileLoaded,
          onFileLoadError: _onFileLoadError,
          onFileLoading: _onFileLoading,
          multiSelect,
        });
        break;
      case INTEGRATION_DAM_BYNDER:
        await dynamicScriptLoad({
          id: 'bynderJS',
          src: 'https://ucv.bynder.com/5.0.5/modules/compactview/bynder-compactview-3-latest.js',
        });
        loadBynderMedia({
          allowedFileTypes,
          onFileLoaded,
          onFileLoadError: _onFileLoadError,
          onFileLoading: _onFileLoading,
          multiSelect,
        });
        break;
      default:
        break;
    }
  }

  return openService;
}
