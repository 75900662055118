import { INTEGRATION_DAM_BYNDER } from '@/storychief/constants/Constants';
import convertTextToSlug from '@/storychief/utils/convertTextToSlug';

export default function loadBynderMedia({
  allowedFileTypes,
  onFileLoaded,
  onFileLoadError,
  onFileLoading,
  multiSelect,
}) {
  const imagesAllowed = !!allowedFileTypes.find((_fileType) => _fileType.mime.startsWith('image'));
  const videosAllowed = !!allowedFileTypes.find((_fileType) => _fileType.mime.startsWith('video'));
  const assetTypes = [];
  if (imagesAllowed) {
    assetTypes.push('image');
  }
  if (videosAllowed) {
    assetTypes.push('video');
  }

  window.BynderCompactView.open({
    theme: {
      colorButtonPrimary: '#5bfbbd',
    },
    assetTypes,
    mode: multiSelect ? 'MultiSelect' : 'SingleSelect',
    onSuccess: (assets) => {
      onFileLoading();
      const filesArray = [...assets];
      try {
        Promise.all(
          filesArray.map(
            (_file) =>
              new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                let url = null;
                switch (_file.type) {
                  case 'VIDEO': {
                    url = _file.previewUrls[0];
                    break;
                  }
                  case 'IMAGE':
                  default: {
                    url = _file.files.webImage.url;
                    break;
                  }
                }

                xhr.open('GET', url);
                xhr.responseType = 'blob';
                xhr.addEventListener('error', reject);
                xhr.addEventListener('load', (event) => {
                  const blob = event.currentTarget.response;
                  resolve({
                    blob,
                    name: convertTextToSlug(_file.name) + url.substring(url.lastIndexOf('.')),
                  });
                });

                xhr.send();
              }),
          ),
        )
          .then((objects) => {
            const _files = objects.map((_object) => {
              const _file = new File([_object.blob], _object.name, {
                type: _object.blob.type,
                lastModifiedDate: new Date(),
              });
              _file.source = INTEGRATION_DAM_BYNDER;
              return _file;
            });
            onFileLoaded(_files);
          })
          .catch((e) => {
            onFileLoadError(e);
          });
      } catch (e) {
        onFileLoadError(e);
      }
    },
  });
}
