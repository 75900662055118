import { INTEGRATION_DAM_CLOUDINARY } from '@/storychief/constants/Constants';

export default function loadCloudinaryMedia({
  integrationInfo,
  allowedFileTypes,
  onFileLoaded,
  onFileLoadError,
  onFileLoading,
  multiSelect,
}) {
  const imagesAllowed = !!allowedFileTypes.find((_fileType) => _fileType.mime.startsWith('image'));
  const videosAllowed = !!allowedFileTypes.find((_fileType) => _fileType.mime.startsWith('video'));
  const config = {
    cloud_name: integrationInfo.cloud_name,
    api_key: integrationInfo.api_key,
    insert_caption: 'Insert',
    multiple: multiSelect,
  };
  if (!imagesAllowed || !videosAllowed) {
    // only add resource_type if one of the types is not allowed
    config.folder = { path: '', resource_type: imagesAllowed ? 'image' : 'video' };
  }
  window.cloudinary.openMediaLibrary(config, {
    insertHandler: ({ assets } = {}) => {
      onFileLoading();
      const filesArray = [...assets];

      try {
        Promise.all(
          filesArray.map(
            (_file) =>
              new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                const url = _file.derived ? _file.derived[0].secure_url : _file.secure_url;
                xhr.open('GET', url);
                xhr.responseType = 'blob';
                xhr.addEventListener('error', reject);
                xhr.addEventListener('load', (event) => {
                  const blob = event.currentTarget.response;
                  resolve({
                    blob,
                    name: url.substring(url.lastIndexOf('/') + 1),
                    caption: _file.context?.custom?.caption || '',
                    alt: _file.context?.custom?.alt || '',
                  });
                });

                xhr.send();
              }),
          ),
        )
          .then((objects) => {
            const _files = objects.map((_object) => {
              const _file = new File([_object.blob], _object.name, {
                type: _object.blob.type,
                lastModifiedDate: new Date(),
              });
              _file.source = INTEGRATION_DAM_CLOUDINARY;
              _file.caption = _object.caption;
              _file.alt = _object.alt;
              return _file;
            });
            onFileLoaded(_files);
          })
          .catch((e) => {
            onFileLoadError(e);
          });
      } catch (e) {
        onFileLoadError(e);
      }
    },
  });
}
