/**
 * @param {object} data
 * @param {string} data.id
 * @param {string} data.src
 * @param {string} data.dataAppKey
 * @returns {Promise}
 */
export default function dynamicScriptLoad({ id, src, dataAppKey }) {
  return new Promise((resolve) => {
    const isExistingScript = document.getElementById(id) !== null;

    if (isExistingScript) {
      resolve();
    } else {
      const script = document.createElement('script');
      if (id) script.setAttribute('id', id);
      if (src) script.setAttribute('src', src);
      if (dataAppKey) script.setAttribute('data-app-key', dataAppKey);
      script.setAttribute('type', 'text/javascript');
      document.body.appendChild(script);
      script.onload = () => {
        resolve();
      };
    }
  });
}
